import React from 'react';
import CountUp from 'react-countup';
import { Grid, Typography } from '@mui/material';
import { PeopleAlt, ThumbUpAlt, CheckCircle, TrendingUp } from '@mui/icons-material';

function NumberCounter() {
  return (
    <Grid container spacing={4} justifyContent="center" alignItems="center">
      <CounterItem icon={<PeopleAlt fontSize="large" />} end={52}  label="Clientes" description="Total de clientes atendidos" />
      <CounterItem icon={<ThumbUpAlt fontSize="large" />} end={90} label="Taxa de Satisfação" description="Percentual de taxa dos clientes satisfeitos" />
      <CounterItem icon={<CheckCircle fontSize="large" />} end={60} label="Projetos Concluídos" description="Numero total de projetos finalizados" />
      <CounterItem icon={<TrendingUp fontSize="large" />} end={45} label="Aumento do Faturamento" description=" % media de aumento de faturamento dos clientes" />
    </Grid>
  );
}

function CounterItem({ icon, end, label, description }) {
  return (
    <Grid item xs={12} sm={6} md={3} marginBlockEnd={10}>

      <Typography variant="h4" align="center">
        <CountUp className='isVisible' start={0} end={end} duration={10.5} separator="," />
      </Typography>
  	      
      <Typography variant="subtitle1" align="center" style={{ fontWeight: 'bold' }}>
        {icon} {label}
      </Typography>
      <Typography variant="body2" align="center" style={{ color: '#888' }}>
        {description}
      </Typography>
    </Grid>
  );
}

export default NumberCounter;
