import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import virtus from '../assets/img/virtus.jpeg'; // Corrigindo o caminho de importação
const WhatsAppButton = () => {
  return (
    <div>
      <FloatingWhatsApp
        avatar={virtus} // Replace this with your avatar image
        phoneNumber="75998569937" // Replace this with your WhatsApp phone number
        accountName="Virtus Company" // Replace this with your WhatsApp account name
        chatMessage="Olá, Como podemos ajudar ?" // Default message
        placeholder='Escreva uma mensagem '
        statusMessage="Respondemos em até 1 hora "
        allowEsc

      />
    </div>
  );
};

export default WhatsAppButton;
