import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useForm, ValidationError } from '@formspree/react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export const Contact = () => {
  const [buttonText, setButtonText] = useState('Enviar');
  const [status, setStatus] = useState({});
  const [state, handleSubmit] = useForm("xkndprap");
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
 

  const handlePrivacyPolicyChange = (event) => {
    setPrivacyPolicyChecked(event.target.checked);
  };

  if (state.succeeded) {
    return (
      <section className="contact text-center" id="connect">
        <Container>
          <Row className="align-items-center">
            <Col size={12}>
              <h2>Obrigado por Entrar em contato!</h2>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Entre em contato </h2>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                        <input type="text" name="firstName" placeholder="Nome" />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="text" name="lastName" placeholder="Sobrenome" />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="email" name="email" placeholder="Email " />
                        <ValidationError 
                          prefix="Email" 
                          field="email"
                          errors={state.errors}
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="tel" name="phone" placeholder="Celular" />
                      </Col>
                      <Col size={12} className="px-1">
                        <textarea rows="6" name="message" placeholder="Quais são seus objetivos contratando a Virtus?"></textarea>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={privacyPolicyChecked}
                                onChange={handlePrivacyPolicyChange}
                                name="privacyPolicyChecked"
                                color="primary"
                              />
                            }
                            label={
                              <span>
                            Eu concordo com a &nbsp;
                            <a href="/politica-de-privacidade" target="_blank" rel="noopener noreferrer">Política de Privacidade</a>
                          </span>
                            }
                          />
                        </FormGroup>
                        <button type="submit" disabled={state.submitting || !privacyPolicyChecked}><span>{buttonText}</span></button>
                      </Col>
                      {
                        status.message &&
                        <Col>
                          <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                        </Col>
                      }
                    </Row>
                  </form>
                </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
