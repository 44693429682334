import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const PrivacyPolicy = () => {
  return (
    <div className="container mt-5">
      <h2>Política de Privacidade</h2>
      <p>
        Esta política de privacidade descreve como coletamos, usamos e protegemos seus dados pessoais. Ao utilizar nosso site/app, você concorda com os termos desta política.
      </p>

      <h3>Informações coletadas</h3>

      <ul className="list-group">
        <li className="list-group-item">Nome</li>
        <li className="list-group-item">Email</li>
        <li className="list-group-item">Mensagem</li>    
            <li className="list-group-item">Telefone </li>

      </ul>

      <h3>Uso das informações</h3>

      <ul className="list-group">
        <li className="list-group-item">Responder suas solicitações e dúvidas.</li>
        <li className="list-group-item">Enviar informações sobre nossos produtos e serviços (marketing).</li>
        <li className="list-group-item">Melhorar a qualidade de nossos serviços.</li>

      </ul>

      <h3>Compartilhamento de informações</h3>

      <p>
        Seus dados não serão compartilhados com terceiros, exceto quando necessário para cumprir obrigações legais ou proteger nossos direitos.
      </p>

      <h3>Segurança dos dados</h3>

      <p>
        Tomamos medidas de segurança para proteger seus dados contra acesso não autorizado, perda ou roubo.
      </p>

      <h3>Direitos dos titulares</h3>

      <p>
        Você tem o direito de acessar, corrigir, atualizar ou excluir seus dados. Você também pode solicitar a portabilidade dos seus dados e opor-se ao seu tratamento.
      </p>

      <h3>Contato</h3>

      <p>
        Se você tiver dúvidas ou solicitações relacionadas à política de privacidade, entre em contato conosco através do email{' '}
        <a href="mailto:virtuscompanymkt@gmail.com">virtuscompanymkt@gmail.com</a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
