import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App  from './App'; // Seu componente principal
import PrivacyPolicy from './components/PrivacyPolicy';

function Routing() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} /> {/* Rota para o componente principal */}
          <Route  path="/politica-de-privacidade" element={<PrivacyPolicy />} /> {/* Rota para o componente de política de privacidade */}
        </Routes>
      </BrowserRouter>
    );
  }
  
  export default Routing;
  