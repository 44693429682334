import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import estetica from "../assets/img/estetica.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import ecommerce from '../assets/img/ecommerce.png'
import card4 from '../assets/img/card4.gif'
import card1 from '../assets/img/card1.png'
import card2 from '../assets/img/card2.png'
import clinica from '../assets/img/clinica.jpg'
import case1 from '../assets/img/case.png'
import case2 from '../assets/img/case2.png'
export const Projects = () => {
  const cases = [
    {
      title: "Card Para eventos ",
      description: "Card bonito para eventos",
      imgUrl: case1,
    },
    {
      title: "Card para clínicas ",
      description: " Bonito card para clínicas ",
      imgUrl: case2,
    },
  ]
  const cards = [
    {
      title: "Card Para eventos ",
      description: "Card bonito para eventos",
      imgUrl: card1,
    },
    {
      title: "Card para clínicas ",
      description: " Bonito card para clínicas ",
      imgUrl: card2,
    },
    {
      title: "Imobiliária ",
      description: "Design moderno para imobiliárias ",
      imgUrl: card4,
    },
  ]
  const projects = [
    {
      title: "Sistema de Agendamento ",
      description: "Site completo para clínicas ",
      imgUrl: clinica,
    },
    {
      title: "Comércio Online ",
      description: "Site com um layout incrível ",
      imgUrl: ecommerce,
    },
    {
      title: "Clínica de estética  ",
      description: "Design para clínica de estética",
      imgUrl: estetica,
    },
  ]

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projetos </h2>
                <p className="text-justify">
              Nossa equipe de especialistas está pronta para oferecer soluções personalizadas para atender às suas necessidades específicas, confira nossos projetos
          </p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Sites </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Design </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Sucesso </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row >
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>

                    
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          cards.map((card, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...card}
                                />
                            )
                          })
                        }
                      </Row>     
            </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row className="justify-content-center">
                        {
                          cases.map((item, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...item}
                                />
                            )
                          })
                        }
                      </Row>                         
                      </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt='img '></img>
    </section>
  )
}
